import { Action, PlayerItem, PieceItem } from '@chinese-checkers/core';

/**
 * useReducer で格納される情報はバックエンドから送られてきたデータです。
 */
export type State = {
  /** プレイヤー */
  players: PlayerItem[],
  /** 駒 */
  pieces: PieceItem[],
  /** 現在のターンの色番号 (開始前であれば 0) */
  turn: number,
  /** 勝者の色番号 (勝者がなければ 0) */
  winnerColor: number
};

export const initialState: State = {
  pieces: [],
  players: [],
  turn: 0,
  winnerColor: 0
};

export function Reducer(state: State, action: Action): State {
  console.log(state, action);
  switch (action.type) {
    case "get":
      return {
        players: action.players,
        pieces: action.pieces,
        turn: action.turn,
        winnerColor: 0
      };
    case "start":
      return {
        ...state,
        players: action.players
      };
    case "start_complete":
      return {
        ...state,
        players: action.players,
        pieces: action.pieces,
        turn: action.turn
      };
    case "move":
      return {
        ...state,
        pieces: action.pieces,
        turn: action.turn
      };
    case "move_finished":
      return {
        ...state,
        pieces: action.pieces,
        winnerColor: action.winnerColor
      }
    case "leave":
      return {
        ...state,
        players: action.players
      }
  }
  return state;
}
