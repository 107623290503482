"use strict";
exports.__esModule = true;
exports.PieceTable = void 0;
var BoardConfig_1 = require("./BoardConfig");
var PieceTable = /** @class */ (function () {
    function PieceTable(pieces) {
        this.table = this.reload(pieces);
    }
    PieceTable.prototype.reload = function (pieces) {
        var table = BoardConfig_1.boardMap.map(function (line) { return line.map(function (_number) { return null; }); });
        pieces.forEach(function (piece) {
            table[piece.y][piece.x] = {
                id: piece.id,
                color: piece.color
            };
        });
        return table;
    };
    PieceTable.prototype.validateMove = function (x, y) {
        var _this = this;
        var piece = this.table[y][x];
        if (piece === null) {
            return [];
        }
        return BoardConfig_1.directions
            .map(function (direction) { return direction(x, y); })
            .filter(function (movedCoord) { return (PieceTable.isInArea(movedCoord.x, movedCoord.y, piece.color) &&
            !_this.isInPiece(movedCoord.x, movedCoord.y)); })
            .concat(this.getJumpedCoord([], piece.color, x, y));
    };
    PieceTable.prototype.getJumpedCoord = function (movedHistory, yourColorId, x, y) {
        var _this = this;
        var jumpedCoords = BoardConfig_1.directions
            .map(function (direction) { return [direction(x, y), direction]; })
            // 近くに駒がある
            .filter(function (_a) {
            var movedCoord = _a[0];
            return (PieceTable.isInArea(movedCoord.x, movedCoord.y, yourColorId) &&
                _this.isInPiece(movedCoord.x, movedCoord.y));
        })
            // ジャンプしてみる
            .map(function (_a) {
            var movedCoord = _a[0], direction = _a[1];
            return direction(movedCoord.x, movedCoord.y);
        })
            // ジャンプした先が空き
            .filter(function (jumpedCoord) { return (PieceTable.isInArea(jumpedCoord.x, jumpedCoord.y, yourColorId) &&
            !_this.isInPiece(jumpedCoord.x, jumpedCoord.y) &&
            !movedHistory.some(function (coord) { return coord.x === x && coord.y === y; })); });
        if (jumpedCoords.length <= 0) {
            return [];
        }
        return jumpedCoords.concat(jumpedCoords.flatMap(function (jumpedCoord) { return (_this.getJumpedCoord(movedHistory.concat({ x: x, y: y }), yourColorId, jumpedCoord.x, jumpedCoord.y)); }));
    };
    /**
     * 指定した座標が移動可能なのかを調べます。
     * @param x 移動したい X 座標
     * @param y 移動したい X 座標
     * @returns 移動可能か
     */
    PieceTable.isInArea = function (x, y, yourColorId) {
        return (x >= 0 && y >= 0 &&
            y < BoardConfig_1.boardMap.length && x < BoardConfig_1.boardMap[y].length &&
            (BoardConfig_1.boardMap[y][x] === BoardConfig_1.BOARD_ITEM_NUMBER_EMPTY || BoardConfig_1.boardMap[y][x] === yourColorId));
    };
    /**
     * 駒の2次元配列を使用して、指定した座標に駒がないか確認します。
     * @param pieceTable 駒の2次元配列情報 (makePieceTable 関数の戻り値)
     * @param x X 座標
     * @param y X 座標
     * @returns 駒があるか
     */
    PieceTable.prototype.isInPiece = function (x, y) {
        return this.table[y][x] !== null;
    };
    return PieceTable;
}());
exports.PieceTable = PieceTable;
