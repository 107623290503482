import './Board.css';
import { CHIP_WIDTH } from '../config';
import { SelectingState } from '../utils/selecting';
import { Coord, PieceItem, boardMap, BOARD_ITEM_NUMBER_INVALID, goalAreas } from '@chinese-checkers/core';
import BoardChip from './BoardChip';
import Piece from './Piece';
import PieceMark from './PieceMark';

function Board(props: {
  pieces: PieceItem[],
  currentColor: number,
  isSelecting: SelectingState,
  canMoveAreas: Coord[],
  /** 場所をクリックした場合に実行されるメソッド */
  onChipClick: (x: number, y: number) => void,
  /** 駒をクリックした場合に実行されるメソッド */
  onPieceClick: (id: number, x: number, y: number) => void
}) {

  const boardLines = boardMap.length;
  // TODO: もうちょっとまともな値にできるかもしれない
  const width = boardLines * CHIP_WIDTH;
  const height = boardLines * CHIP_WIDTH;

  function getAbsoluteCoord(x: number, y: number): Coord {
    const firstChipX = boardMap[0].findIndex(number => number !== BOARD_ITEM_NUMBER_INVALID);
    return {
      x: x * CHIP_WIDTH - (y * (CHIP_WIDTH / 2)) + (firstChipX * CHIP_WIDTH),
      y: y * CHIP_WIDTH
    }
  }

  return (
    <div className="Board">
      <div className="Board-chips" style={{ width, height }}>
        {boardMap.map((line, y) => line.map((number, x) => {
          if (number === BOARD_ITEM_NUMBER_INVALID) {
            return null;
          }
          const coord = getAbsoluteCoord(x, y);
          const piece = props.pieces.find(piece => piece.x === x && piece.y === y);
          const isGoal = props.isSelecting.isSelecting && (goalAreas.get(props.currentColor)?.some(coord => coord.x === x && coord.y === y) ?? false);
          const canMove = props.canMoveAreas.some(coord => coord.x === x && coord.y === y);
          return (
            <BoardChip
              key={`${x},${y}`}
              color={number}
              x={coord.x}
              y={coord.y}
              onClick={() => props.onChipClick(x, y)}
            >
              {piece !== undefined &&
                <Piece
                  color={piece.color}
                  isSelect={props.isSelecting.isSelecting && props.isSelecting.pieceId === piece.id}
                  onClick={() => props.onPieceClick(piece.id, x, y)}
                />
              }
              {piece === undefined && (isGoal || canMove) &&
                <PieceMark
                  colorNumber={number}
                  isGoal={isGoal}
                  canMove={canMove}
                />
              }
            </BoardChip>
          );
        }))}
      </div>
    </div>
  );
}

export default Board;
