import React, { FormEvent, useEffect, useReducer, useState } from 'react';
import Board from './components/Board';
import Status from './components/Status';
import Form from './components/Form';
import './App.css';
import { initialState, Reducer } from './State';
import { Mode } from './utils/Mode';
import { Action, Coord, PieceTable } from '@chinese-checkers/core';
import useWebSocket from 'react-use-websocket';
import { DISAPPEAR_ALERT_MS, FORCE_RESET_KEYWORD } from './config';
import { SelectingState } from './utils/selecting';

function App() {
  const [state, dispatch] = useReducer(Reducer, initialState);

  const websocketUrl = process.env.REACT_APP_BACKEND_PORT !== undefined ?
    `${process.env.REACT_APP_BACKEND_HOST}:${process.env.REACT_APP_BACKEND_PORT}` :
    process.env.REACT_APP_BACKEND_HOST ?? "";
  const {
    sendJsonMessage,
    lastJsonMessage
  } = useWebSocket(websocketUrl, {
    onOpen: () => {
      sendJsonMessage({
        type: "get"
      });
    },
    shouldReconnect: (closeEvent) => true
  });

  /**
   * 選択状態を示す State です。
   */
  const [selecting, setSelecting] = useState<SelectingState>({ isSelecting: false });
  /**
   * ゲームモードです。
   */
  const [mode, setMode] = useState<Mode>("waiting");
  /**
   * プレイヤーが現在参加している色の番号です。参加していなければ 0 になります。
   */
  const [joiningColor, setJoin] = useState(0);
  /**
   * プレイヤーの名前です。
   */
  const [yourName, setYourName] = useState("");
  /**
   * アラートメッセージです。空白にすると表示されなくなります。
   */
  const [alertMessageValue, setAlertMessage] = useState("");
  /**
   * 移動可能な場所の配列です。
   */
  const [canMoveAreas, setCanMoveAreas] = useState<Coord[]>([]);
  /**
   * 強制リセット時に「強制リセット」と入力するためのテキストボックスを保持する文字列です。
   */
  const [forceResetInput, setForceResetInput] = useState("");

  const currentColor = state.players.some(player => player.color === joiningColor) ? joiningColor : 0;
  const pieceTable = new PieceTable(state.pieces);

  function alertMessage(message: string) {
    setAlertMessage(message);
    setTimeout(() => {
      setAlertMessage("");
    }, DISAPPEAR_ALERT_MS);
  }

  function onChipClick(x: number, y: number) {
    if (!selecting.isSelecting) {
      return;
    }
    const canMoveOrErrorMessage = checkMovingPiece();
    if (typeof canMoveOrErrorMessage === "string") {
      alertMessage(canMoveOrErrorMessage);
      return;
    }
    if (!canMoveAreas.some(coord => coord.x === x && coord.y === y)) {
      alertMessage("この場所には移動できません。");
      return;
    }
    sendJsonMessage({
      type: "move",
      pieceId: selecting.pieceId,
      x,
      y
    });
    setSelecting({
      isSelecting: false
    });
    setCanMoveAreas([]);
  }

  function onPieceClick(id: number) {
    if (selecting.isSelecting) {
      return;
    }
    const canMoveOrErrorMessage = checkMovingPiece();
    if (typeof canMoveOrErrorMessage === "string") {
      alertMessage(canMoveOrErrorMessage);
      return;
    }
    const piece = state.pieces.find(piece => piece.id === id);
    if (piece === undefined) {
      alertMessage("不明な駒です。");
      return;
    }
    if (piece.color !== currentColor) {
      alertMessage("相手の駒は移動できません。");
      return;
    }
    const canMoves = pieceTable.validateMove(piece.x, piece.y);
    if (canMoves.length <= 0) {
      alertMessage("その駒は移動できません。");
      return;
    }
    setCanMoveAreas(canMoves);
    setSelecting({
      isSelecting: true,
      pieceId: id
    });
  }

  /**
   * 駒の移動操作が許されているか確認します。
   * 駒自体の移動の可否は確認しません。
   * @returns 駒の移動操作ができれば true、できなければエラーメッセージ (文字列)
   */
  function checkMovingPiece(): true | string {
    if (state.turn === 0) {
      return "ゲームは始まっていません。";
    }
    if (state.winnerColor !== 0) {
      return "ゲームは終了しました。";
    }
    if (currentColor === 0) {
      return "観戦客は駒の操作ができません。";
    }
    if (state.turn !== currentColor) {
      return "今のターンはあなたの番ではありません。";
    }
    return true;
  }

  function onNameInput(event: FormEvent<HTMLInputElement>) {
    setYourName(event.currentTarget.value);
  }

  function onJoinButtonClick(colorId: number) {
    if (yourName === "") {
      alertMessage("なにか名前を入力してください。");
      return;
    }
    if (state.players.some(player => player.color === colorId)) {
      alertMessage("その色はすでに別のプレイヤーが参加しています。");
      return;
    }
    setJoin(colorId);
    sendJsonMessage({
      type: "start",
      colorId: colorId,
      name: yourName
    });
  }

  function onSelectCancelButtonClick() {
    setSelecting({
      isSelecting: false
    });
    setCanMoveAreas([]);
  }

  function onForceResetInput(event: FormEvent<HTMLInputElement>) {
    setForceResetInput(event.currentTarget.value);
  }

  function forceReset() {
    if (forceResetInput !== FORCE_RESET_KEYWORD) {
      return;
    }
    sendJsonMessage({
      type: "reset"
    });
  }

  useEffect(() => {
    console.log(lastJsonMessage);
    if (lastJsonMessage !== null) {
      if (lastJsonMessage.type === "error") {
        alertMessage(lastJsonMessage.message);
      } else {
        const message: Action = lastJsonMessage;
        dispatch(message);
        switch (message.type) {
          case "get":
            if (message.turn !== 0) {
              setMode("game");
            }
            break;
          case "start_complete":
            setMode("game");
            break;
          case "move_finished":
            setMode("finished");
            break;
          case "leave":
            setMode("waiting");
        }
      }
    }
  }, [lastJsonMessage]);

  return (
    <div className="App">
      {alertMessageValue !== "" &&
        <div className="App-alert">
          <div
            className="App-alert-message message message--alert"
            onClick={() => setAlertMessage("")}
          >{alertMessageValue}</div>
        </div>
      }
      <div className="App-content">
        <Status
          players={state.players}
          mode={mode}
          turn={state.turn}
          currentColor={currentColor}
        />
        <div>
          <Board
            pieces={state.pieces}
            currentColor={currentColor}
            isSelecting={selecting}
            canMoveAreas={canMoveAreas}
            onChipClick={onChipClick}
            onPieceClick={onPieceClick}
          />
          <div className="App-select-status">
            {selecting.isSelecting &&
              <>
                現在選択中
                <button onClick={onSelectCancelButtonClick}>キャンセル</button>
              </>
            }
          </div>
        </div>
        <Form
          name={yourName}
          currentColor={currentColor}
          onInput={onNameInput}
          isGameStarted={state.turn !== 0}
          onJoin={onJoinButtonClick}
          players={state.players}
        />
      </div>
      <div className="App-force-reset">
        <input type="text" value={forceResetInput} onInput={onForceResetInput} placeholder={FORCE_RESET_KEYWORD}></input>
        <button onClick={forceReset}>強制リセット</button>
      </div>
    </div>
  );
}

export default App;
