import { MouseEventHandler } from "react";
import { findColorById } from "../utils/color";

import "./Piece.css";

function Piece(props: {
  color: number,
  isSelect: boolean,
  onClick: MouseEventHandler<HTMLImageElement | HTMLDivElement>
}) {
  const color = findColorById(props.color);
  const className = `Piece ${props.isSelect ? "Piece--select" : ""}`;
  return (
    <div className={className} onClick={props.onClick}>
      {color !== undefined &&
        <img
          src={`assets/${color.pieceImagePath}`}
          alt={color.name}
          onClick={props.onClick}
        />
      }
    </div>
  )
}

export default Piece;
