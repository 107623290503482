import { colors } from "../config";

export type ColorItem = {
  name: string,
  cssName: string,
  pieceImagePath: string
};

export function findColorById(number: number): ColorItem | undefined {
  return colors[number - 1] ?? undefined;
}
