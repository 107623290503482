import { MouseEventHandler, ReactNode } from "react";
import { CHIP_WIDTH } from "../config";
import { findColorById } from "../utils/color";
import './BoardChip.css';
import '../assets/board_chip.png';

/**
 * 盤の1マス分のコンポーネントです。
 */
function BoardChip(props: {
  color: number,
  x: number,
  y: number
  children: ReactNode,
  onClick: MouseEventHandler<HTMLDivElement> | undefined
}) {
  const colorItem = findColorById(props.color);
  const className =
    "BoardChip-inside " +
    (colorItem !== undefined ? `BoardChip-inside--${colorItem.cssName} ` : " ");
  return (
    <div
      className="BoardChip"
      style={{
        position: "absolute",
        left: props.x,
        top: props.y,
        width: CHIP_WIDTH,
        height: CHIP_WIDTH
      }}
      onClick={props.onClick}
    >
      <div className={className}>
        {props.children}
      </div>
    </div>
  );
}

export default BoardChip;
