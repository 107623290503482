"use strict";
/**
 * 盤に関する設定が含まれています。
 */
exports.__esModule = true;
exports.PLAYERS_LIMIT = exports.BOARD_ITEM_NUMBER_EMPTY = exports.BOARD_ITEM_NUMBER_INVALID = exports.directions = exports.goalAreas = exports.boardMap = void 0;
/**
 * 盤のマップ
 * 99: 無効地帯 (侵入不可能)
 *  0: 空白 (どの駒も侵入可能)
 * 1-: その色専用の地帯
 */
exports.boardMap = [
    [99, 99, 99, 3],
    [99, 99, 99, 3, 3],
    [99, 99, 99, 3, 3, 3],
    [1, 1, 1, 0, 0, 0, 0, 2, 2, 2],
    [99, 1, 1, 0, 0, 0, 0, 0, 2, 2],
    [99, 99, 1, 0, 0, 0, 0, 0, 0, 2],
    [99, 99, 99, 0, 0, 0, 0, 0, 0, 0],
    [99, 99, 99, 2, 0, 0, 0, 0, 0, 0, 1],
    [99, 99, 99, 2, 2, 0, 0, 0, 0, 0, 1, 1],
    [99, 99, 99, 2, 2, 2, 0, 0, 0, 0, 1, 1, 1],
    [99, 99, 99, 99, 99, 99, 99, 3, 3, 3],
    [99, 99, 99, 99, 99, 99, 99, 99, 3, 3],
    [99, 99, 99, 99, 99, 99, 99, 99, 99, 3]
];
/**
 * 駒のゴール
 * キーは駒の色番号
 * 値はゴールの座標の配列
 */
exports.goalAreas = new Map([
    [
        1, [
            { x: 10, y: 7 },
            { x: 10, y: 8 },
            { x: 11, y: 8 },
            { x: 10, y: 9 },
            { x: 11, y: 9 },
            { x: 12, y: 9 }
        ]
    ],
    [
        2, [
            { x: 3, y: 7 },
            { x: 3, y: 8 },
            { x: 4, y: 8 },
            { x: 3, y: 9 },
            { x: 4, y: 9 },
            { x: 5, y: 9 }
        ]
    ],
    [
        3, [
            { x: 3, y: 0 },
            { x: 3, y: 1 },
            { x: 4, y: 1 },
            { x: 3, y: 2 },
            { x: 4, y: 2 },
            { x: 5, y: 2 }
        ]
    ]
]);
/**
 * 駒の移動方向を定義した関数の配列です。
 */
exports.directions = [
    function (x, y) { return ({ x: x - 1, y: y - 1 }); },
    function (x, y) { return ({ x: x, y: y - 1 }); },
    function (x, y) { return ({ x: x - 1, y: y }); },
    function (x, y) { return ({ x: x + 1, y: y }); },
    function (x, y) { return ({ x: x, y: y + 1 }); },
    function (x, y) { return ({ x: x + 1, y: y + 1 }); }
];
exports.BOARD_ITEM_NUMBER_INVALID = 99;
exports.BOARD_ITEM_NUMBER_EMPTY = 0;
/**
 * 参加できるプレイヤーの上限
 */
exports.PLAYERS_LIMIT = 3;
