import { ColorItem } from "./utils/color";

/** 1マス分のサイズ */
export const CHIP_WIDTH = 48;

/** 駒1つ分のサイズ */
export const PIECE_WIDTH = 32;

export const colors: ColorItem[] = [
  {
    name: "赤",
    cssName: "red",
    pieceImagePath: "piece-red.png"
  }, {
    name: "緑",
    cssName: "green",
    pieceImagePath: "piece-green.png"
  }, {
    name: "青",
    cssName: "blue",
    pieceImagePath: "piece-blue.png"
  }
];

/**
 * アラートメッセージを表示し続ける時間 (ミリ秒)
 */
export const DISAPPEAR_ALERT_MS = 5000;

export const FORCE_RESET_KEYWORD = "qazwsx";
