import { ColorItem, findColorById } from "../utils/color";
import "./Piece.css";

function PieceMark(props: {
  isGoal: boolean,
  canMove: boolean,
  colorNumber: number
}) {
  const color = findColorById(props.colorNumber);
  const className = `Piece ${makeGoalClassName(color, props.isGoal)} ${props.canMove ? "Piece--can-move" : ""}`;
  return (
    <div className={className}></div>
  );
}

function makeGoalClassName(color: ColorItem | undefined, isGoal: boolean) {
  if (!isGoal) {
    return "";
  }
  if (color === undefined) {
    return "Piece--is-goal";
  }
  return `Piece--is-goal Piece--is-goal--${color.cssName}`;
}

export default PieceMark;
