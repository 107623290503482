import { PlayerItem } from "@chinese-checkers/core";
import { ColorItem, findColorById } from "../utils/color";
import { Mode } from "../utils/Mode";

function Status(props: {
  /** プレイヤー一覧 */
  players: PlayerItem[],
  /** 現在のモード */
  mode: Mode,
  /** 現在のターンの色番号 */
  turn: number,
  /** 参加中の色番号 */
  currentColor: number
}) {

  const turnColor = findColorById(props.turn);
  const turnName = props.players.find(player => player.color === props.turn)?.name ?? "";

  function ColorView(props: { colorItem: ColorItem | undefined }) {
    if (props.colorItem === undefined) {
      return <></>;
    }
    return <>({props.colorItem.name}色)</>
  }

  function StatusPlayers() {
    return (
      <div className="Status-players">
        {props.players.map((player, index) => {
          const playerColor = findColorById(player.color);
          return (
            <div key={index} className={`Status-players-player text--color-${playerColor?.cssName ?? ""}`}>
              {player.name} <ColorView colorItem={playerColor} />
            </div>
          );
        })}
      </div>
    );
  }

  function StatusText() {
    switch (props.mode) {
      case "waiting":
        return <>ただいま参加できます。</>;
      case "game":
        if (props.turn === props.currentColor) {
          return <><strong>あなたの番です。</strong></>;
        }
        return <>現在: {turnName} <ColorView colorItem={turnColor} /></>;
      case "finished":
        if (!turnName) {
          return <><strong>誰か分からんけどとりあえず勝ち！！</strong></>;
        }
        return <><strong>{turnName}の勝ち！！</strong></>;
    }
  }

  return (
    <div className="Status message">
      <StatusPlayers />
      <StatusText />
    </div>
  );
}

export default Status;
