import { PlayerItem } from "@chinese-checkers/core";
import { FormEventHandler } from "react";
import { colors } from "../config";
import { findColorById } from "../utils/color";

function Form(props: {
  players: PlayerItem[],
  currentColor: number,
  name: string,
  isGameStarted: boolean,
  onInput: FormEventHandler<HTMLInputElement>,
  onJoin: (color: number) => void
}) {
  if (props.currentColor !== 0) {
    const color = findColorById(props.currentColor);
    return (
      <div className={`message message--color-${color?.cssName ?? ""}`}>
        <div>
          {props.name}
        </div>
        {color !== undefined &&
          <div>
            現在 {color.name}色 に参加中
          </div>
        }
      </div>
    );
  }
  // 未参加 ＋ ゲームスタート中 ＝ 観戦状態
  if (props.isGameStarted) {
    return (
      <div className="message">
        ただいま観戦中
      </div>
    );
  }
  return (
    <div>
      <div>
        <input type="text" value={props.name} onInput={props.onInput}></input>
      </div>
      <div>
        {colors.map((color, index) => {
          const id = index + 1;
          return (
            <button
              className={`button button--color-${color.cssName} text--color-${color.cssName}`}
              key={id}
              disabled={props.players.some(player => player.color === id)}
              onClick={() => props.onJoin(id)}
            >
              {color.name}色で参加
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default Form;
